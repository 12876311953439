import {
  Button,
  Card,
  Chip,
  CircularProgress,
  FormControl,
  Grid,
  Select,
  Typography,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { getInferenceItemStatus } from "../../../helpers";
import * as QCBotService from "../../../services/qcbot.service";
import * as QCBotActions from "../../../store/qcbot/qcbot.actions";

const appendOp = (num) => {
  if (num === 0) return "";
  return `${num > 0 ? "+" : ""}${num}`;
};

const InferenceListItem = (props) => {
  const [auditValue, setAuditValue] = useState("");
  const [reason, setReason] = useState("");
  const [skuImages, setSkuImages] = useState(false);

  const { data, auditPallet, inferenceId, dcId, expanded, onExpand, loading } =
    props;

  useEffect(() => {
    if (expanded) {
      QCBotService.getSKUPhotos(data.upc, data.vor_reference_number).then(
        ({ data }) => {
          setSkuImages(data);
        }
      );
    }
  }, [expanded]);

  useEffect(() => {
    if (data?.is_qc_bot_audited_sku) {
      setAuditValue(Number(data?.qc_bot_audited_quantity));
      if (data?.qc_bot_audit_reason !== null)
        setReason(data?.qc_bot_audit_reason);
    }
  }, [data]);

  if (!data) return null;

  const badgeMap = {
    "NO ERROR": "badge-matched",
    EXTRA: "badge-not-matched",
    MISSING: "badge-not-matched",
    AUDITED: "badge-audited",
    "CAN'T CONFIRM": "badge-cannot-confirm",
  };

  const boundingColorMap = {
    "NO ERROR": "#0d9c57",
    EXTRA: "#db2d2d",
    MISSING: "#db2d2d",
    AUDITED: "#00b0f0",
    "CAN'T CONFIRM": "#fbc813",
  };

  const status = getInferenceItemStatus(data);

  const { end_url, side_url } = skuImages || {};

  const onConfirmValue = () => {
    auditPallet(inferenceId, dcId, [
      {
        is_qc_bot_audited_sku: 1,
        inference_sku_data_id: data.inference_sku_data_id,
        qc_bot_audited_quantity: auditValue,
        qc_bot_audit_reason: reason,
      },
    ]);
  };

  return (
    <div className="inference-list-item-container">
      {(status === "EXTRA" || status === "MISSING") && (
        <div className="error-identifier" color="primary" badgeContent=" " />
      )}

      <Card className="inference-list-item">
        <Grid
          container
          alignItems="center"
          justify="space-between"
          wrap="nowrap"
          onClick={() => onExpand(boundingColorMap[status])}
        >
          <div>
            <Typography className="qty-number">
              {data.expected_quantity}
            </Typography>
            <Typography className="qty-text">QTY</Typography>
          </div>
          <Typography className="description">
            {data?.long_description}
          </Typography>

          <div className="right-part">
            {status === "AUDITED" && (
              <Typography component="h1" className="qty-number">
                {appendOp(
                  Number(data?.qc_bot_audited_quantity) -
                    Number(data.expected_quantity)
                )}
              </Typography>
            )}

            {(status === "EXTRA" || status === "MISSING") && (
              <Typography component="h1" className="qty-number">
                {appendOp(
                  Number(data?.actual_quantity) - Number(data.expected_quantity)
                )}
              </Typography>
            )}
            <div className="chip-container">
              {data.is_un_trained_sku && data.is_qc_bot_audited_sku === null ? (
                <div className={`chip ${badgeMap.EXTRA}`}>UNTRAINED</div>
              ) : (
                <div label={status} className={`chip ${badgeMap[status]}`}>
                  {status}
                </div>
              )}
            </div>
          </div>
        </Grid>
        {expanded && (
          <>
            {(side_url || end_url) && (
              <Grid container justify="center" className="sku-image-container">
                <Grid item className="sku-image-item">
                  <img src={side_url} className="sku-image" />
                  <Typography component="p">SIDE OF SKU</Typography>
                </Grid>

                <Grid item className="sku-image-item">
                  <img src={end_url} className="sku-image" />
                  <Typography component="p">END OF SKU</Typography>
                </Grid>
              </Grid>
            )}
            <Grid
              container
              justify="center"
              className="inference-detail-content"
            >
              <div className="inference-detail-item">
                <Typography className="inference-detail-label">
                  EXPECTED
                </Typography>
                <Typography className="inference-detail-quantity">
                  {data.expected_quantity}
                </Typography>
              </div>

              <div>
                <Typography className="inference-detail-label">
                  FOUND
                </Typography>
                <Typography className="inference-detail-quantity">
                  {data.actual_quantity}
                </Typography>
              </div>
            </Grid>

            <Grid
              container
              justify="center"
              className="inference-detail-content"
            >
              <div>
                <Typography className="inference-detail-label">
                  ACTUAL
                </Typography>
                <input
                  type="text"
                  className="inference-detail-actual-quantity-input"
                  value={auditValue}
                  onChange={(e) => setAuditValue(e.target.value)}
                />
              </div>
            </Grid>

            {data.actual_quantity < data.expected_quantity && (
              <Grid
                container
                justify="center"
                className="inference-detail-content"
              >
                <FormControl
                  variant="filled"
                  className="inference-item-reason-select"
                >
                  <Select
                    value={reason}
                    onChange={(e) => setReason(e.target.value)}
                    native
                  >
                    <option aria-label="None" value="">
                      SELECT REASON
                    </option>
                    <option value={"out-of-stock"}>OUT OF STOCK ITEM(S)</option>
                    <option value={"short-ship"}>SHORT-SHIP ITEM(S)</option>
                    <option value={"incorrect-detection"}>INCORRECT DETECTION</option>
                    <option value={"picker-error"}>PICKER ERROR</option>
                    <option value={"unknown"}>UNKNOWN</option>
                  </Select>
                </FormControl>
              </Grid>
            )}

            <Grid
              container
              justify="center"
              className="inference-detail-content"
            >
              <Button
                className="primary confirm-actual-count-button"
                size="large"
                disabled={
                  loading ||
                  auditValue === "" ||
                  (status === "MISSING" && !reason)
                }
                onClick={onConfirmValue}
              >
                {loading ? (
                  <CircularProgress color="white" size={30} />
                ) : (
                  "Confirm Actual Count"
                )}
              </Button>
            </Grid>
          </>
        )}
      </Card>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.confirmActualLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    auditPallet: (inferenceId, dcId, data) => {
      dispatch(QCBotActions.QCBOT_AUDIT_PALLET(inferenceId, dcId, data));
    },
    fetchPalletDetail: (inferenceId, dcId) =>
      dispatch(QCBotActions.QCBOT_LOAD_PALLET_DETAIL(inferenceId, dcId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InferenceListItem);
